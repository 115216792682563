import React, { useEffect, useState } from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import Home from "./pages/Home/Home";
import Footer from "./components/Footer/Footer";
import AppBar from "./components/AppBar/AppBar";
import EnrollPage from "./pages/EnrollPage";
import DepartmentPage from "./pages/DepartmentPage/DepartmentPage";
import AboutPage from "./pages/AboutPage/AboutPage";
import EventsBookingPage from "./pages/EventsBookingPage/EventsBookingPage";
import NewsPage from "./pages/NewsPage";
import PartnersPage from "./pages/PartnersPage";
import DonatePage from "./pages/DonatePage";
import AdminPage from "./pages/AdminPage";
import InstrumentMarket from "./pages/InstrumentMarket/InstrumentMarket";
import Policies from "./pages/Policies/Policies";
import ThankyouPage from "./pages/ThankyouPage/ThankyouPage";
import Gallery from "./components/Gallery";
import StudentSpotlight from "./pages/StudentSpotlight";
import Camps from "./pages/Camps/Camps";

function App() {
  const [password, setPassword] = useState("");
  const handlePassword = (e) => {
    setPassword(e.target.value);
    localStorage.setItem("password", password);
  };

  useEffect(() => {
    localStorage.password && setPassword(localStorage.password);
  }, []);

  if (password !== "bacononastick") {
    return (
      <div style={{ backgroundColor: "rgb(90, 0, 0)" }}>
        <AppBar />
        <div style={{ height: 100 }} />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/enroll' component={EnrollPage} />
          <Route path='/donate' component={DonatePage} />
          <Route path='/about' component={AboutPage} />
          <Route path='/partners' component={PartnersPage} />
          <Route path='/news' component={NewsPage} />
          <Route path='/events' component={EventsBookingPage} />
          <Route path='/department/:title' component={DepartmentPage} />
          <Route path='/admin' component={AdminPage} />
          <Route path='/instruments' component={InstrumentMarket} />
          <Route path='/policies' component={Policies} />
          <Route path='/thankyou' component={ThankyouPage} />
          <Route path='/gallery' component={Gallery} />
          <Route path='/student-spotlight' component={StudentSpotlight} />
          <Route path='/camps' component={Camps} />
          <Route path='/campsworkshops' component={Camps} />
        </Switch>
        <Footer />
      </div>
    );
  } else {
    return (
      <div>
        <h4>Please enter the password...</h4>
        <input onChange={handlePassword} />
      </div>
    );
  }
}

export default App;

import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { getAboutPage } from "../../contentful";
import marked from "marked";

import { Box, Typography } from "@material-ui/core";

import TeachersHero from "../../components/TeachersHero";

import styles from "./AboutPageStyles";

const AboutPage = ({ classes }) => {
  const [content, setContent] = useState({});

  useEffect(() => {
    getAboutPage().then((data) => {
      setContent(data[0].fields);
    });
  }, []);
  return (
    <Box className={classes.container}>
      <Box className={classes.housePictureContainer}>
        {content.houseImage && (
          <img
            style={{ width: "100%" }}
            src={content.houseImage.fields.file.url}
          />
        )}
      </Box>
      <Box className={classes.generalInfo} mb={4}>
        {content.hillhurstBiog && (
          <Typography
            style={{ padding: 8, flex: 1 }}
            dangerouslySetInnerHTML={{ __html: marked(content.hillhurstBiog) }}
          />
        )}
        {content.mainImage && (
          <Box className={classes.generalInfoImageContainer}>
            <img
              src={content.mainImage.fields.file.url}
              style={{ width: "100%" }}
            />
          </Box>
        )}
      </Box>
      <Box className={classes.panel}>
        <Box>
          {content.aimImage && (
            <img
              style={{ width: 150 }}
              src={content.aimImage.fields.file.url}
            />
          )}
        </Box>
        {content.aimBiog && (
          <Typography
            dangerouslySetInnerHTML={{ __html: marked(content.aimBiog) }}
          />
        )}
      </Box>
      <Box className={classes.panel}>
        <Box>
          {content.gregImage && (
            <img
              style={{ width: 150 }}
              src={content.gregImage.fields.file.url}
            />
          )}
        </Box>
        {content.gregBiog && (
          <Typography
            dangerouslySetInnerHTML={{ __html: marked(content.gregBiog) }}
          />
        )}
      </Box>

      <TeachersHero />
    </Box>
  );
};

export default withStyles(styles)(AboutPage);

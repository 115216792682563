import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { getEnrollmentPage } from "../../contentful";
import marked from "marked";

import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Textfield from "@material-ui/core/TextField";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Logo from "../../assets/LOGO.png";

import styles from "./EnrollPageStyles";
import EnrollmentHero from "../../components/EnrollmentHero/EnrollmentHero";
import LocationHero from "../../components/LocationHero";

const EnrollPage = ({ classes }) => {
  const [content, setContent] = useState({});
  const [text, setText] = useState("");

  useEffect(() => {
    getEnrollmentPage().then((data) => {
      setContent(data[0].fields);
      setText(data[0].fields.text);
    });
  }, []);

  const onSubmit = () => {};

  return (
    <Box className={classes.container}>
      <Box className={classes.panel}>
        <Typography variant='h4' color='primary'>
          Enrollment at RCM
        </Typography>

        {content && <Typography dangerouslySetInnerHTML={{ __html: text }} />}
      </Box>
      <EnrollmentHero />
      {content.fees && (
        <Box className={classes.panel}>
          <Typography
            dangerouslySetInnerHTML={{ __html: marked(content.fees) }}
          />
        </Box>
      )}
      <Box mt={5}>
        <LocationHero />
      </Box>
    </Box>
  );
};

export default withStyles(styles)(EnrollPage);

import React, { useState, useEffect } from "react";
import { getPartners } from "../../contentful";
import { withStyles } from "@material-ui/core/styles";

import { Box, Typography } from "@material-ui/core";

import styles from "./PartnersPageStyles";

const PartnersPage = ({ classes }) => {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    getPartners().then((data) => setPartners(data));
  }, []);
  return (
    <Box className={classes.container}>
      <Typography variant='h4'>Our Partners</Typography>

      <Box className={classes.cardsContainer}>
        {partners.map((p) => (
          <Box className={classes.partnerCard}>
            {p.fields.image && (
              <Box>
                <Box className={classes.avatar}>
                  <img src={p.fields.image.fields.file.url} />
                </Box>
              </Box>
            )}
            <Box className={classes.cardText}>
              <Typography variant='h5'>{p.fields.name}</Typography>
              <Typography variant='h6'>{p.fields.title}</Typography>
              <Typography variant='body1'>{p.fields.biography}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default withStyles(styles)(PartnersPage);

export default (theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  timesContainer: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    marginRight: theme.spacing(2),
  },
  bookingContainer: {
    display: "flex",
  },
  bookingForm: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },

  input: {
    marginBottom: theme.spacing(3),
  },
});

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { SRLWrapper } from "simple-react-lightbox";
import marked from "marked";

import { Box, Typography } from "@material-ui/core";

import styles from "./InstrumentCardStyles";
const InstrumentCard = ({ classes, instrument }) => {
  return (
    <Box className={classes.container}>
      <Box>
        <Typography className={classes.title}>
          {instrument.fields.name}
        </Typography>
        {instrument.fields.description && (
          <Typography
            dangerouslySetInnerHTML={{
              __html: marked(instrument.fields.description),
            }}
          />
        )}
        <Typography>
          {instrument.fields.currency}
          {instrument.fields.price}
        </Typography>
        {instrument.fields.contactDetails && (
          <>
            <Box className={classes.divider} />
            <Typography
              dangerouslySetInnerHTML={{
                __html: marked(instrument.fields.contactDetails),
              }}
            />
          </>
        )}
      </Box>
      {instrument.fields.mainImage && (
        <Box>
          <img src={instrument.fields.mainImage.fields.file.url} />
        </Box>
      )}
      {instrument.fields.imageGallery && (
        <Box className={classes.galleryContainer}>
          <SRLWrapper>
            {instrument.fields.imageGallery.map((img) => (
              <img className={classes.galleryImage} src={img.fields.file.url} />
            ))}
          </SRLWrapper>
        </Box>
      )}
    </Box>
  );
};

export default withStyles(styles)(InstrumentCard);

import React, { useState } from "react";
import "./camp-card-styles.scss";
import { Typography, Button, Box } from "@material-ui/core";
import marked from "marked";
import { withStyles } from "@material-ui/core/styles";
import styles from "./CampCardStyles";
import TeacherDetailsModal from "../../components/TeacherDetailsModal";

const CampCard = ({ camp, archive, classes }) => {
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const {
    title,
    subtitle,
    tag,
    dates1,
    dates2,
    description,
    link,
    mainImage,
    tuitionFee,
    teacher,
  } = camp.fields;

  const handleTeacherSelect = (teacher) => {
    setSelectedTeacher(teacher);
    setDetailsModal(true);
  };

  return (
    <div className='camp-card'>
      {mainImage && (
        <div className='camp-card__image'>
          <img src={mainImage.fields.file.url} />
        </div>
      )}
      <div className='camp-card__text'>
        {title && <Typography className='camp-card__title'>{title}</Typography>}
        {subtitle && (
          <Typography className='camp-card__subtitle'>{subtitle}</Typography>
        )}
        <hr />
        {dates1 && (
          <Typography
            className={
              !archive
                ? "camp-card__dates"
                : "camp-card__dates camp-card__dates--archived"
            }
          >
            {dates1}
          </Typography>
        )}
        {dates2 && (
          <Typography
            className={
              !archive
                ? "camp-card__dates"
                : "camp-card__dates camp-card__dates--archived"
            }
          >
            {dates2}
          </Typography>
        )}
        {tag && (
          <Typography
            className={
              !archive
                ? "camp-card__tag"
                : "camp-card__tag camp-card__tag--archived"
            }
          >
            {tag}
          </Typography>
        )}
        {!archive && (
          <div className='camp-card__button'>
            <Button variant='contained' color='secondary'>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href={`${link}`}
                target='_blank'
              >
                {" "}
                Register
              </a>
            </Button>
          </div>
        )}

        {!archive && (
          <>
            {description && (
              <Typography
                dangerouslySetInnerHTML={{ __html: marked(description) }}
              />
            )}
            <Box className={classes.divider} />

            <Box mt={4} mb={1}>
              {teacher && (
                <Typography variant='h5'>Meet Our Instructors</Typography>
              )}
            </Box>
            <Box className={classes.teacherCardContainer}>
              {teacher?.map((teacher) => {
                console.log(teacher);
                return (
                  <Box
                    onClick={() => handleTeacherSelect(teacher.fields)}
                    className={classes.teacherCard}
                  >
                    <div className={classes.teacherCardContent}>
                      <Box>
                        <Typography variant='body2'>
                          {teacher?.fields?.name}
                        </Typography>

                        {/* {teacher.fields.instrumentvoice && (
                          <Typography variant='body2'>
                            {teacher?.fields?.instrumentvoice}
                          </Typography>
                        )} */}
                      </Box>
                      <Box className={classes.teacherCardImage}>
                        <img src={teacher?.fields?.image.fields.file.url} />
                      </Box>
                    </div>
                  </Box>
                );
              })}
            </Box>
            <TeacherDetailsModal
              teacher={selectedTeacher}
              open={detailsModal}
              onClose={() => setDetailsModal(false)}
            />
            <Box className={classes.divider} />
            <Typography>{tuitionFee}</Typography>
          </>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(CampCard);

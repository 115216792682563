import React, { useState, useRef, useEffect } from "react";
import { getActivities } from "../../contentful";
import { withStyles } from "@material-ui/core/styles";
import { motion } from "framer-motion";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import styles from "./ActivitiesHeroStyles";

const SlideItem = ({ item }) => (
  <motion.div
    initial={{ opacity: 0, x: 100 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: -100 }}
    transition={{ duration: 0.8 }}
    style={{
      // width: 600,
      margin: "auto",
      textAlign: "center",
      marginTop: 45,
    }}
  >
    <Typography variant='h6' color='primary'>
      {item.fields.title}
    </Typography>
    <Typography variant='body1' color='primary'>
      <em> {item.fields.description}</em>
    </Typography>
  </motion.div>
);

const TimerSlideMs = 8000;

const ActivitiesHero = ({ classes }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const [activities, setActivities] = useState([]);

  useEffect(() => {
    getActivities().then((data) => {
      setActivities(data);
      clearInterval(timerSlider.current);

      timerSlider.current = setInterval(
        () =>
          setCurrentIndex((currentIndex) => (currentIndex + 1) % data.length),
        TimerSlideMs
      );

      return () => {
        clearInterval(timerSlider.current);
      };
    });
  }, []);

  const timerSlider = useRef(null);

  return (
    <Box className={classes.container}>
      <Box mb={4} style={{ textAlign: "center" }}>
        <Typography variant='h4'>Activities at Rocsato Conservatory</Typography>
        <Typography variant='body1'>
          Here is a taster of just some of the many activities which will be
          presented by us, our students, friends, and special guests.
        </Typography>
      </Box>
      {activities.map(
        (item, i) => currentIndex === i && <SlideItem key={i} item={item} />
      )}
    </Box>
  );
};

export default withStyles(styles)(ActivitiesHero);

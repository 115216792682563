import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { Box, Typography } from "@material-ui/core";
import styles from "./WelcomeHeroStyles";

const WelcomeHero = ({ classes }) => {
  return (
    <Box className={classes.container}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography variant='h2'>
          Rocsato Conservatory of Music at Hillhurst
        </Typography>
        <Typography>
          Welcome to the brand new website of the Rocsato Conservatory of Music.
          Here you can find the latest information on all our activities,
          including:
        </Typography>{" "}
        <li>
          <Typography>Private Lessons in all departments</Typography>
        </li>
        <li>
          <Typography>
            Weekend and Summer Camps in Musical Theater, Brass, Woodwind and
            Voice
          </Typography>
        </li>
        <li>
          <Typography>
            Master classes in all departments both in person and virtual
          </Typography>
        </li>
        <li>
          <Typography>
            Special performances by special guests as well as faculty
          </Typography>
        </li>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(WelcomeHero);

export default (theme) => ({
  formContainer: {
    padding: theme.spacing(5),
    minHeight: 700,
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  dateChipInput: {
    border: "none",
    backgroundColor: "transparent",
    color: theme.palette.secondary.main,
  },
  dateChip: {
    color: theme.palette.secondary.main,
    backgroundColor: "#eee",
    display: "flex",
    justifyContent: "space-between",
    width: 200,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: 12,
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
});

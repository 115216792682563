export default (theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    padding: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      textAlign: "center",
      alignItems: "center",
    },
  },
  logoContainer: {
    width: 400,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: theme.spacing(3),
    },
  },
});

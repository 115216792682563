import React from "react";
import { withstyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import { Box, Typography, withStyles } from "@material-ui/core";

import styles from "./FooterStyles";

const Footer = ({ classes }) => {
  return (
    <Box className={classes.container}>
      <Typography variant='subtitle2'>
        Designed and built by{" "}
        <a
          style={{ color: "lightgray", textDecoration: "none" }}
          href='https:webspinner.eu'
          target='_blank'
        >
          www.WebSpinner.eu
        </a>
      </Typography>
      <Box>
        <Link to='/admin' className={classes.link}>
          <Typography variant='subtitle2'>Admin</Typography>
        </Link>
        <Link to='/policies' className={classes.link}>
          <Typography variant='subtitle2'>Policies & Procedures</Typography>
        </Link>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(Footer);

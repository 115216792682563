import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { Box, Typography, Button } from "@material-ui/core";

import Logo from "../../assets/coffee-logo.png";

import styles from "./CoffeeHeroStyles";

const CoffeeHero = ({ classes }) => {
  return (
    <Box className={classes.container}>
      <Box>
        <Typography variant='h6' color='primary'>
          Hillhurst is also the proud new home of
        </Typography>
        <Typography color='primary' variant='h5'>
          Rocsato Coffee Roasters
        </Typography>
        <Box mt={4}>
          <a
            style={{ textDecoration: "none" }}
            href='https://rocsatocoffeeroasters.com'
            target='_blank'
          >
            <Button variant='contained' color='primary'>
              Visit the website
            </Button>
          </a>
        </Box>
      </Box>
      <Box className={classes.logoContainer}>
        <img src={Logo} style={{ width: "100%" }} />
      </Box>
    </Box>
  );
};

export default withStyles(styles)(CoffeeHero);

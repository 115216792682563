import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { getDepartments } from "../../contentful";

import { Box, Typography } from "@material-ui/core";

import TeacherDetailsModal from "../TeacherDetailsModal";

import styles from "./TeachersHeroStyles";

const TeachersHero = ({ classes }) => {
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    getDepartments().then((data) => setDepartments(data));
  }, []);

  const handleSelect = (teacher) => {
    setSelectedTeacher(teacher.fields);
    setDetailsModal(true);
  };

  return (
    <div className={classes.container}>
      <Typography variant='h5'>Our Teaching Staff</Typography>
      {departments.map((dep) => (
        <>
          <Box className={classes.departmentListItem}>
            {dep.fields.teachers && dep.fields.teachers.length > 0 && (
              <>
                <Box
                  style={{
                    height: 1,
                    backgroundColor: "rgb(200, 200, 200)",
                    marginBottom: 16,
                  }}
                />
                <Typography variant='h6' className={classes.departmentTitle}>
                  {dep.fields.title}
                </Typography>
              </>
            )}
            {dep.fields.teachers &&
              dep.fields.teachers.map((teacher) => (
                <Box
                  onClick={() => handleSelect(teacher)}
                  className={classes.teacherCard}
                >
                  <Box>
                    <Box className={classes.avatar}>
                      <img src={teacher.fields.image.fields.file.url} />
                    </Box>
                  </Box>
                  <Box>
                    <Typography>
                      {teacher.fields.name} - {teacher.fields.instrumentvoice}
                    </Typography>
                    <Typography>
                      {teacher.fields.biography
                        .split(" ")
                        .slice(0, 50)
                        .join(" ") + "..."}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>
        </>
      ))}
      <TeacherDetailsModal
        teacher={selectedTeacher}
        open={detailsModal}
        onClose={() => setDetailsModal(false)}
      />
    </div>
  );
};

export default withStyles(styles)(TeachersHero);

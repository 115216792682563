import React, { useState, useEffect } from "react";
import "./styles.scss";
import { getGalleryImages } from "../../contentful";
import { Typography } from "@material-ui/core";
import GalleryDisplay from "../GalleryDisplay/GalleryDisplay";
const Gallery = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    getGalleryImages().then((data) => setImages(data));
  }, []);

  return (
    <>
      <div className='header'>
        <Typography variant='h4' color='primary'>
          The Gallery
        </Typography>
        <GalleryDisplay images={images} />
      </div>
    </>
  );
};

export default Gallery;

export default (theme) => ({
  container: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2, 5),
  },
  contactButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  emailIcon: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
});

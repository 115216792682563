import React from "react";
import { withStyles } from "@material-ui/core/styles";
import marked from "marked";
import { Link } from "react-router-dom";

import { Dialog, Box, Typography, IconButton } from "@material-ui/core";
import Close from "@material-ui/icons/Close";

import styles from "./TeacherDetailsModalStyles";

const TeacherDetailsModal = ({ classes, open, onClose, teacher }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      {teacher && (
        <>
          <Box
            style={{ display: "flex", justifyContent: "flex-end", padding: 8 }}
          >
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <Box className={classes.container}>
            <Box className={classes.headerContainer}>
              <Box className={classes.headerText}>
                <Typography variant='h5'>{teacher.name}</Typography>
                <Typography>{teacher.instrumentvoice}</Typography>
                <hr />
              </Box>
              {teacher.image && (
                <Box className={classes.imageContainer}>
                  <img src={teacher.image.fields.file.url} />
                </Box>
              )}
            </Box>
            <Box
              dangerouslySetInnerHTML={{ __html: marked(teacher.biography) }}
            />
          </Box>
        </>
      )}
    </Dialog>
  );
};

export default withStyles(styles)(TeacherDetailsModal);

import React, { useState, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";

import useSendEmail from "../../hooks/useSendEMail";

import {
  Dialog,
  Box,
  Typography,
  TextField,
  Button,
  ButtonGroup,
  IconButton,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";

import Logo from "../../assets/LOGO.png";

import styles from "./ContactModalStyles";
const ContactModal = ({ classes, open, onClose }) => {
  const { sendMail, sent } = useSendEmail();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const body = `<div><h4>New message from ${name}</h4><p>${email}</p><p>Subject: ${subject}</p><hr/><p>${message}</p></div>`;
  const msgSubject = `New message from ${name} `;

  const sendEMail = (e) => {
    sendMail(body, msgSubject);
    onClose();
  };
  return (
    <Dialog open={open} close={onClose}>
      <Box className={classes.container}>
        <Box mb={3}>
          <img src={Logo} style={{ height: 100 }} />
        </Box>
        <form className={classes.form}>
          <TextField
            label='Name'
            className={classes.input}
            variant='outlined'
            name='name'
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label='Email'
            className={classes.input}
            variant='outlined'
            name='reply_to'
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label='Subject'
            className={classes.input}
            variant='outlined'
            name='subject'
            onChange={(e) => setSubject(e.target.value)}
          />
          <TextField
            label='Write your message here'
            multiline
            rows={6}
            className={classes.input}
            variant='outlined'
            name='message'
            onChange={(e) => setMessage(e.target.value)}
          />
          <ButtonGroup>
            <Button onClick={onClose}>Cancel</Button>
            <Button type='button' onClick={sendEMail}>
              Send
            </Button>
          </ButtonGroup>
        </form>
      </Box>
    </Dialog>
  );
};

export default withStyles(styles)(ContactModal);

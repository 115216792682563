import React from "react";
import "./LoadingStyles.css";

const Loading = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className='lds-ellipsis'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loading;

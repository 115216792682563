import React from "react";
import { withStyles } from "@material-ui/core/styles";

import styles from "./AdvertCardStyles";

const AdvertCard = ({ classes, image }) => (
  <div className={classes.container}>
    <img src={image} />
  </div>
);

export default withStyles(styles)(AdvertCard);

export default (theme) => ({
  container: {
    color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    padding: theme.spacing(0, 5),
    "& img": {
      width: "30%",
      marginBottom: theme.spacing(9),
      [theme.breakpoints.down("xs")]: {
        width: "60%",
      },
    },
  },

  text: {
    fontSize: 20,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
});

import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

import House from "../../assets/house.jpg";

import styles from "./MainHeroStyles";

const MainHero = ({ classes }) => (
  <Box
    className={classes.container}
    style={{ backgroundImage: `url(${House})` }}
  ></Box>
);

export default withStyles(styles)(MainHero);

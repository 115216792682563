export default (theme) => ({
  container: {
    color: theme.palette.primary.main,

    padding: theme.spacing(3),
  },
  panel: {
    boxShadow: "2px 2px 8px black",
    padding: theme.spacing(5),
    borderRadius: 12,
  },
  generalInfo: {
    boxShadow: "2px 2px 8px black",
    padding: theme.spacing(5),
    borderRadius: 12,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  generalInfoImageContainer: {
    width: 300,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  housePictureContainer: {
    boxShadow: "2px 2px 8px black",
    padding: theme.spacing(5),
    borderRadius: 12,
    display: "flex",
    justifyContent: "center",
  },
});

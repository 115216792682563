import React, { useContext, useEffect, useState } from "react";
import { useMainContext } from "../../context";
import { withStyles } from "@material-ui/core/styles";
import { getEventsSpaces } from "../../contentful";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";

import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";

import RoomsBookingPage from "../RoomsBookingPage/RoomsBookingPage";
import SmartCalendar from "../../components/SmartCalendar";
import styles from "./AdminPageStyles";

const AdminPage = ({ classes }) => {
  const [venues, setVenues] = useState([{ fields: { name: "Concert Hall" } }]);

  const {
    user,
    signout,
    handleLogIn,
    setEmail,
    email,
    setPassword,
    password,
    fetchBookings,
    venueBookings,
  } = useMainContext();

  useEffect(() => {
    getEventsSpaces().then((data) => setVenues(data));
    fetchBookings();
  }, []);

  return (
    <Box className={classes.container}>
      <Typography variant='h5'>RCM ADMIN</Typography>
      {!user && (
        <Box className={classes.loginPanel}>
          <TextField
            value={email}
            label='Email'
            variant='outlined'
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            value={password}
            label='Password'
            variant='outlined'
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button variant='contained' onClick={handleLogIn}>
            Sign In
          </Button>
        </Box>
      )}
      {user && (
        <>
          <Box mt={3}>
            <Button variant='contained' onClick={signout}>
              Sign Out
            </Button>
            <Box className={classes.divider} />
            <Typography variant='h5'>Events Manager</Typography>
            <Box className={classes.divider} />

            <Typography variant='h5'>
              <b>Manage All Bookings</b>
            </Typography>

            <SmartCalendar showAll admin />
            <Box className={classes.divider} />
          </Box>
          {venues.map((venue) => (
            <Box mt={4}>
              <Typography variant='h6'>{venue.fields.name}</Typography>
              <SmartCalendar
                viewerOnly
                byVenue={venue}
                venue={venue.fields.name}
                admin
              />
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

export default withStyles(styles)(AdminPage);

import React, { useEffect, useState } from "react";
import "./styles.scss";
import { getStudentSpotlight } from "../../contentful";
import { Typography, Button } from "@material-ui/core";
import marked from "marked";

const StudentSpotlight = () => {
  const [contents, setContents] = useState(null);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getStudentSpotlight().then((data) => setContents(data));
  }, []);
  return (
    <div className='spotlight-outer-container'>
      {contents && (
        <>
          <div className='spotlight-container'>
            <div className='spotlight-container__text-container'>
              <Typography className='spotlight-container__title'>
                Student Spotlight
              </Typography>

              <Typography
                className='spotlight-container__details'
                dangerouslySetInnerHTML={{
                  __html: marked(contents[index].fields.text),
                }}
              />
              <div className='spotlight-container__line' />
              <Typography className='spotlight-container__name'>
                {contents[index].fields.name}
              </Typography>
              <Typography className='spotlight-container__instrument'>
                {contents[index].fields.instrument}
              </Typography>
            </div>
            <div className='spotlight-container__image'>
              <img src={contents[index].fields.image.fields.file.url} />
            </div>
          </div>
          {contents.length > 1 && (
            <div style={{ marginTop: 8 }}>
              <Typography color='primary'>
                Browse previous featured students
              </Typography>
              {index > 0 && (
                <Button
                  color='primary'
                  onClick={() => setIndex((index) => index - 1)}
                >
                  previous
                </Button>
              )}
              {index < contents.length - 1 && (
                <Button
                  onClick={() => setIndex((index) => index + 1)}
                  color='primary'
                >
                  next
                </Button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default StudentSpotlight;

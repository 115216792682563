export default (theme) => ({
  container: {
    color: theme.palette.primary.main,
    backgroundColor: "black",
    padding: theme.spacing(2, 5),
    display: "flex",
    justifyContent: "space-between",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontWeight: "bold",
    cursor: "pointer",
  },
});

export default (theme) => ({
  container: {
    padding: theme.spacing(5),
    margin: theme.spacing(6),
    boxShadow: "2px 2px 8px black",
    borderRadius: 12,
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(4, 0),
      padding: theme.spacing(2),
    },
  },
  galleryImage: {
    width: 200,
    height: 200,
    overflow: "hidden",
    cursor: "pointer",
    margin: theme.spacing(1),
    "& img": {
      width: "170%",
    },
    [theme.breakpoints.down("xs")]: {
      width: 100,
      height: 100,
    },
  },
  gallery: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    marginBottom: theme.spacing(3),
    "& img": {
      width: "100%",
    },
  },
  info: {
    width: 600,
    margin: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
});

import React, { createContext, useState, useEffect } from "react";
import firebase from "../firebase";

export const MainContext = createContext(null);

export const ContextProvider = ({ children }) => {
  const [contactModal, setContactModal] = useState(false);
  const [sideNav, setSideNav] = useState(false);
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [venueBookings, setVenueBookings] = useState([]);

  const authListener = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  };

  const handleLogIn = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        setUser(user);
        console.log(user);
      })
      .catch((error) => {
        let errorMessage = error.message;
        console.log(errorMessage);

        setEmail("");
        setPassword("");
      });
  };

  const signout = () => {
    firebase.auth().signOut();
    setUser(null);
  };

  const addBooking = async (booking) => {
    let avatar = "";
    if (user.photoURL) {
      avatar = user.photoURL;
    }

    await firebase.database().ref("bookings").push(booking);
  };

  const deleteBooking = (booking) => {
    const array = venueBookings;
    const filtered = array.filter((date) => date.k !== booking.k);
    setVenueBookings(filtered);
    firebase.database().ref(`bookings/${booking.k}`).set({});
  };

  const fetchBookings = () => {
    firebase
      .database()
      .ref("bookings")
      .on("value", (snapshot) => {
        const bookings = snapshot.val();

        let keys = [];
        if (bookings) {
          keys = Object.keys(bookings);
        }

        let array = [];
        for (let i = 0; i < keys.length; i++) {
          let k = keys[i];
          let contact = bookings[k].contact;
          let date = bookings[k].date;
          let email = bookings[k].email;
          let bookingDetails = bookings[k].bookingDetails;
          let space = bookings[k].space;
          let time = bookings[k].time;

          array.push({ k, contact, date, time, email, bookingDetails, space });
        }

        setVenueBookings(array);
        console.log(array);
      });
  };

  useEffect(() => {
    authListener();
  }, []);

  const value = {
    contactModal,
    setContactModal,
    sideNav,
    setSideNav,
    handleLogIn,
    signout,
    email,
    setEmail,
    password,
    setPassword,
    user,
    addBooking,
    fetchBookings,
    venueBookings,
    deleteBooking,
  };

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
};

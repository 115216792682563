import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { getDepartment } from "../../contentful";
import marked from "marked";
import { SRLWrapper } from "simple-react-lightbox";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import EnrollmentHero from "../../components/EnrollmentHero/EnrollmentHero";
import TeacherDetailsModal from "../../components/TeacherDetailsModal";

import styles from "./DepartmentPageStyles";

const DepartmentPage = ({ classes }) => {
  const [department, setDepartment] = useState({});
  const { title } = useParams();
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);

  useEffect(() => {
    getDepartment(title).then((data) => setDepartment(data[0].fields));
  }, [title]);

  const handleTeacherSelect = (teacher) => {
    setSelectedTeacher(teacher);
    setDetailsModal(true);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.headerSection}>
        <Box className={classes.headerText}>
          <Typography variant='h3'>{department.title}</Typography>
          <Typography variant='h5'>Department</Typography>
        </Box>
        {department?.mainImage && (
          <Box className={classes.headerImage}>
            <img src={department.mainImage.fields.file.url} />
          </Box>
        )}
      </Box>
      {department?.information && (
        <Typography
          className={classes.panel}
          dangerouslySetInnerHTML={{ __html: marked(department.information) }}
        />
      )}
      <Box className={classes.divider} />
      <Typography>
        To enroll in lessons, please complete the online application form:
      </Typography>
      <EnrollmentHero />
      <Box className={classes.divider} />

      <Box mt={4} mb={1}>
        {department.teachers && (
          <Typography variant='h5'>Meet Our Instructors</Typography>
        )}
      </Box>
      <Box className={classes.teacherCardContainer} >
      {department?.teachers?.map((teacher) => (
        <Box
          onClick={() => handleTeacherSelect(teacher.fields)}
          className={classes.teacherCard}
        >
          <Box>
            <Typography variant='body2'>{teacher.fields.name}</Typography>
            <Typography variant='body2'>
              {teacher.fields.instrumentvoice}
            </Typography>
          </Box>
          <Box className={classes.teacherCardImage}>
            <img src={teacher.fields.image.fields.file.url} />
          </Box>
        </Box>
      ))}
      </Box>
      <TeacherDetailsModal
        teacher={selectedTeacher}
        open={detailsModal}
        onClose={() => setDetailsModal(false)}
      />
      {department.imageGallery && (
        <Box mt={4}>
          <Typography variant='h6'>Gallery</Typography>
          <SRLWrapper>
            <Box className={classes.gallery}>
              {department.imageGallery &&
                department.imageGallery.map((image) => (
                  <Box className={classes.galleryImage}>
                    {" "}
                    <img src={image.fields.file.url} />{" "}
                  </Box>
                ))}
            </Box>
          </SRLWrapper>
        </Box>
      )}
    </Box>
  );
};

export default withStyles(styles)(DepartmentPage);

import { blue } from "@material-ui/core/colors";

export default (theme) => ({
  calendar: {
    paddingTop: theme.spacing(3),
  },
  days: {
    display: "flex",
  },
  day: {
    position: "relative",
    border: "1px solid grey",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    height: 20,
    width: 20,
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: 12,
      width: 10,
      padding: 10,
    },
  },
  selected: {
    position: "realtive",
    color: "green",
    fontWeight: 600,
  },
  before: {
    position: "realtive",
    color: "lightgray",
    cursor: "not-allowed",
  },
  today: {
    position: "realtive",
    color: "blue",
    fontWeight: 600,
    border: "1px solid gray",
    borderRadius: 3,
  },

  header: {
    display: "flex",
    width: 225,
    justifyContent: "space-evenly",
  },
  previous: { cursor: "pointer" },
  next: { cursor: "pointer" },
  dayNames: {
    display: "flex",
  },
  week: {
    height: 20,
    width: 23,
    padding: 20,
    textTransform: "uppercase",
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      height: 10,
      width: 10,
      padding: 11,
    },
  },
  reservationsBadge: {
    position: "absolute",
    top: 0,
    right: 0,
    alignSelf: "flex-end",
    width: 10,
    height: 10,
    color: "white",
    fontSize: 10,
    zIndex: 3,
    padding: 6,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "3px 2px 2px grey",
  },
  booking: {
    border: "1px solid grey",
    borderRadius: 12,
    padding: 12,
    margin: 12,
  },
});

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import AppProvider from "./context/AppProvider";
import { BrowserRouter } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";

const theme = createMuiTheme({
  typography: {
    fontFamily: ['"Merriweather"', "serif"].join(","),
  },
  palette: {
    primary: {
      main: "rgb(200, 200, 200)",
    },
    secondary: {
      main: "rgb(90, 0, 0)",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <SimpleReactLightbox>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </SimpleReactLightbox>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

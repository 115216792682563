export default (theme) => ({
  container: {
    padding: theme.spacing(5),
    maxWidth: 1400,
    margin: "auto",
    color: theme.palette.primary.main,
    "& a": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "& img": {
      width: 600,
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
  },
  headerText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    width: 600,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
});

import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import marked from "marked";
import { SRLWrapper } from "simple-react-lightbox";

import { Box, Typography, Button, Dialog } from "@material-ui/core";

import EventsBookingDialog from "../EventsBookingDialog";

import styles from "./EventsSpaceCardStyles";

const EventsSpaceCard = ({ space, classes }) => {
  const [bookingDialog, setBookingDialog] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState(null);

  const handleDialogOpen = (space) => {
    setSelectedSpace(space);
    setBookingDialog(true);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <img src={space.mainImage.fields.file.url} />

        <Box className={classes.info}>
          <Typography variant='h4'>{space.name}</Typography>
          <Box
            dangerouslySetInnerHTML={{ __html: marked(space.description) }}
          />
          <Button
            color='primary'
            variant='contained'
            onClick={() => handleDialogOpen(space)}
          >
            Reserve our {space.name} for your event
          </Button>
        </Box>
      </Box>

      <SRLWrapper>
        <Box className={classes.gallery}>
          {space.imageGallery.map((image) => (
            <Box className={classes.galleryImage}>
              <img src={image.fields.file.url} />
            </Box>
          ))}
        </Box>
      </SRLWrapper>

      <EventsBookingDialog
        open={bookingDialog}
        onClose={() => setBookingDialog(false)}
        space={selectedSpace}
      />
    </Box>
  );
};

export default withStyles(styles)(EventsSpaceCard);

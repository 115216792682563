import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { getNews } from "../../contentful";
import marked from "marked";
import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookIcon,
  FacebookShareButton,
} from "react-share";
import { SRLWrapper } from "simple-react-lightbox";

import { Box, Typography } from "@material-ui/core";

import styles from "./NewsPageStyles";

const NewsPage = ({ classes }) => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    getNews().then((data) => setNews(data));
  }, []);
  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography variant='h4' color='primary'>
          RCM NEWS
        </Typography>
        <Box>
          <Typography>Why not share our news on your social media!</Typography>
          <Box>
            <div className={classes.sharingButtons}>
              <br></br>
              <TwitterShareButton
                className={classes.sharingButton}
                url={window.location}
                children={<TwitterIcon round size={34} />}
              />
              <LinkedinShareButton
                className={classes.sharingButton}
                url={window.location}
                children={<LinkedinIcon round size={34} />}
              />
              <FacebookShareButton
                className={classes.sharingButton}
                url={window.location}
                children={<FacebookIcon round size={34} />}
              />
            </div>
          </Box>
        </Box>
      </Box>
      {news.map((news) => (
        <Box className={classes.newsFlash}>
          {news.fields.title && (
            <Typography className={classes.title} variant='h4' color='primary'>
              {news.fields.title}
            </Typography>
          )}
          {news.fields.image && (
            <SRLWrapper>
              <img
                src={news.fields.image.fields.file.url}
                className={classes.image}
              />
            </SRLWrapper>
          )}

          {news.fields.text && (
            <Typography
              variant='body1'
              dangerouslySetInnerHTML={{ __html: marked(news.fields.text) }}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

export default withStyles(styles)(NewsPage);

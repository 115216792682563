export default (theme) => ({
  container: {
    padding: theme.spacing(5),
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
  newsFlash: {
    borderRadius: 12,
    boxShadow: "2px 2px 8px black",
    "& a": { color: theme.palette.primary.main },
    padding: theme.spacing(3),
    margin: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
      margin: theme.spacing(2, 0),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  title: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
      textAlign: "center",
    },
  },

  header: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      textAlign: "center",
      margin: theme.spacing(3, 0),
    },
  },
  sharingButtons: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  sharingButton: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  imageContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
  },

  image: {
    maxWidth: "60%",
    marginBottom: 8,
    maxHeight: "100vh",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
});

export default (theme) => ({
  container: {
    padding: theme.spacing(5),
    color: theme.palette.primary.main,
    maxWidth: 1400,
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
  panel: {
    borderRadius: 12,
    boxShadow: "2px 2px 8px black",
    padding: theme.spacing(3),
  },
});

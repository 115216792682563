import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { getEventsSpaces } from "../../contentful";

import { buildCalendar } from "./build";

import { useMainContext } from "../../context";
import styles from "./SmartCalendarStyles";
import BookingsDialog from "./BookingsDialog/BookingsDialog";
import { Typography } from "@material-ui/core";

const TIME_SLOTS = [
  "8am-10am",
  "10am-12pm",
  "12pm-2pm",
  "2pm-4pm",
  "4pm-6pm",
  "6pm-8pm",
  "8pm-10pm",
];

const SmartCalendar = ({
  classes,
  viewerOnly,
  admin,
  byVenue,
  venue,
  showAll,
  setSent={setSent}
}) => {
  const [calendar, setCalendar] = useState([]);

  const {
    addBooking,
    fetchBookings,
    venueBookings,
    deleteBooking,
  } = useMainContext();

  const [bookingsDialog, setBookingsDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [blockedTimes, setBlockedTimes] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [bookingDetails, setBookingDetails] = useState("");
  const [bookingName, setBookingName] = useState("");
  const [bookingEmail, setBookingEmail] = useState("");
  const [date, setDate] = useState(moment());
  const [space, setSpace] = useState("");
  const [spaces, setSpaces] = useState([]);

  useEffect(() => {
    byVenue && setSpace(byVenue.fields.name);
  }, []);

  useEffect(() => {
    getEventsSpaces().then((data) => setSpaces(data));
  }, []);

  useEffect(() => {
    setCalendar(buildCalendar(date));
  }, [date]);

  const updateBookings = () => {
    const newBooking = {
      date: date.format("MM-DD-YYYY"),
      time: selectedTimes,
      contact: bookingName,
      email: bookingEmail,
      bookingDetails: bookingDetails,
      space: space,
    };
    // console.log(newBooking);
    addBooking(newBooking);
    setBookings([...bookings, newBooking]);
    // console.log(bookings);
    setBookingDetails("");
    setBookingEmail("");
    setBookingName("");
    setSpace("");
    setSelectedTimes([]);
    setBlockedTimes([]);
  };

  const isSelected = (day) => {
    return date.isSame(day, "day");
  };

  const beforeToday = (day) => {
    return day.isBefore(new Date(), "day");
  };

  const isToday = (day) => day.isSame(new Date(), "day");

  const dayStyles = (day) => {
    if (isSelected(day)) return classes.selected;
    if (beforeToday(day)) return classes.before;
    if (isToday(day)) return classes.today;
  };

  const currMonthName = () => date.format("MMMM");

  const currYear = () => date.format("YYYY");

  const prevMonth = () => {
    return date.clone().subtract(1, "month");
  };

  const nextMonth = () => {
    return date.clone().add(1, "month");
  };

  const handleDateClick = (date) => {
    if (beforeToday(date)) return;
    setDate(date);
    venueBookings &&
      setSelectedDate(
        venueBookings.filter(
          (booking) => booking.date === date.format("MM-DD-YYYY")
        )
      );

    setBookingsDialog(true);
  };

  const handleTimeSlotClick = (time) => {
    if (selectedTimes.includes(time)) {
      setSelectedTimes(selectedTimes.filter((time) => time !== time));
    } else {
      setSelectedTimes([...selectedTimes, time]);
    }
  };

  return (
    <div className={classes.calendar}>
      <div className={classes.header}>
        <div className={classes.previous} onClick={() => setDate(prevMonth())}>
          {String.fromCharCode(171)}
        </div>

        <div>
          {currMonthName()} {currYear()}
        </div>
        <div className={classes.next} onClick={() => setDate(nextMonth())}>
          {String.fromCharCode(187)}
        </div>
      </div>
      <div className={classes.body}>
        <div className={classes.dayNames}>
          {["s", "m", "t", "w", "t", "f", "s"].map((d) => (
            <div className={classes.week}>
              <Typography>{d}</Typography>
            </div>
          ))}
        </div>
        {calendar.map((week) => (
          <div className={classes.days}>
            {week.map((day) => {
              let reservedTimes = [];
              let bookingsByVenue;
              let bookingsOnDate;

              if (byVenue) {
                bookingsByVenue = venueBookings.filter(
                  (booking) => booking.space === byVenue.fields.name
                );
              } else {
                bookingsOnDate = venueBookings.filter(
                  (booking) => booking.date === day.format("MM-DD-YYYY")
                );
              }
              if (bookingsByVenue) {
                bookingsOnDate = bookingsByVenue.filter(
                  (booking) => booking.date === day.format("MM-DD-YYYY")
                );
              }
              bookingsOnDate &&
                bookingsOnDate.map((booking) =>
                  booking.time.map((time) => reservedTimes.push(time))
                );

              return (
                <div
                  onClick={() => handleDateClick(day)}
                  className={classes.day}
                >
                  {" "}
                  <div
                    style={{ position: "relative" }}
                    className={dayStyles(day)}
                  >
                    {admin && bookingsOnDate && bookingsOnDate.length !== 0 && (
                      <div
                        style={{ backgroundColor: "blue" }}
                        className={classes.reservationsBadge}
                      >
                        {bookingsOnDate.length}
                      </div>
                    )}
                    <Typography>{day.format("D").toString()}</Typography>
                  </div>
                </div>
              );
            })}
          </div>
        ))}{" "}
      </div>
      <BookingsDialog
      setSent={setSent}
        showAll={showAll}
        bookingName={bookingName}
        setBookingName={setBookingName}
        bookingEmail={bookingEmail}
        setBookingEmail={setBookingEmail}
        setBookingDetails={setBookingDetails}
        bookingDetails={bookingDetails}
        bookings={venueBookings}
        timeSlots={TIME_SLOTS}
        handleTimeSlotClick={handleTimeSlotClick}
        selectedDate={selectedDate}
        open={bookingsDialog}
        onClose={() => setBookingsDialog(false)}
        selectedTimes={selectedTimes}
        date={date}
        admin={admin}
        venue={venue}
        updateBookings={updateBookings}
        blockedTimes={blockedTimes}
        deleteBooking={deleteBooking}
        spaces={spaces}
        setSpace={(e) => setSpace(e.target.value)}
        space={space}
        viewerOnly={viewerOnly}
      />
    </div>
  );
};

export default withStyles(styles)(SmartCalendar);

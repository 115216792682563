import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { getEventsSpaces } from "../../contentful";

import { Box, Typography } from "@material-ui/core";

import styles from "./EventsBookingPagesStyles";
import EventsSpaceCard from "../../components/EventsSpaceCard";

const EventsBookingPage = ({ classes }) => {
  const [spaces, setSpaces] = useState([]);

  useEffect(() => {
    getEventsSpaces().then((data) => setSpaces(data));
  }, []);
  return (
    <Box className={classes.container}>
      <Typography variant='h4'>Hold your event at RCM</Typography>
      <Typography variant='h6'>
        Explore our spaces and contact us to make a booking.{" "}
      </Typography>
      {spaces.map((space) => (
        <EventsSpaceCard space={space.fields} />
      ))}
    </Box>
  );
};

export default withStyles(styles)(EventsBookingPage);

import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { getInstrumentsForSale, getRetailerAdverts } from "../../contentful";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import InstrumentCard from "./InstrumentCard";
import styles from "./InstrumentMarketStyles";
import AdvertCard from "./AdvertCard";

const InstrumentMarket = ({ classes }) => {
  const [allInsts, setAllInsts] = useState(true);
  const [instruments, setInstruments] = useState([]);
  const [filteredInstruments, setFilteredInstruments] = useState([]);
  const [cats, setCats] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const uniqueCats = [...new Set(cats)];
  const [adverts, setAdverts] = useState([]);
  const [advertInfo, setAdvertInfo] = useState(false);
  const [listInstrument, setListInstrument] = useState(false);

  useEffect(() => {
    getRetailerAdverts().then((data) => setAdverts(data));
    getInstrumentsForSale().then((data) => {
      setInstruments(data);
      setFilteredInstruments(data);
      instruments && setCats(data.map((inst) => inst.fields.category));
    });
  }, []);

  const filterByCat = (cat) => {
    setAllInsts(false);
    setFilteredInstruments(
      instruments.filter((inst) => inst.fields.category === cat)
    );
  };

  const showAll = () => {
    setFilteredInstruments(instruments);
    setInputValue("");
    setAllInsts(true);
  };

  const handleInputSearch = (e) => {
    const query = e.target.value;
    setInputValue(query);
    setAllInsts(false);
    setFilteredInstruments(
      instruments.filter((inst) =>
        inst.fields.name.toLowerCase().includes(query.toLowerCase())
      )
    );
    if (query === "") {
      setFilteredInstruments(instruments);
      setAllInsts(true);
    }
  };

  return (
    <Box className={classes.outerContainer}>
      <Box className={classes.container}>
        <Box className={classes.introText}>
          <Typography variant='h4'>Instrument Marketplace</Typography>
          <Typography variant='body1'>
            At RCM we recognise the importance of being able to play on quality
            instruments which will compliment the level of tuition, and study
            undertaken. To ensure the best possible chance to progress and reach
            your potential, we have built this page to help you to find your a
            new or used instrument to achieve your goal.{" "}
          </Typography>
          <Box className={classes.modalButtonsContainer}>
            <Button
              onClick={() => setListInstrument(true)}
              variant='outlined'
              color='primary'
              style={{ margin: 4 }}
            >
              Selling Your Instrument
            </Button>
            <Button
              onClick={() => setAdvertInfo(true)}
              variant='outlined'
              color='primary'
              style={{ margin: 4 }}
            >
              Advertise Your Related Business
            </Button>
          </Box>
          <Dialog
            open={listInstrument}
            onClose={() => setListInstrument(false)}
          >
            <Box className={classes.modalContainer}>
              <Typography variant='h6'>
                <b>Selling An Instrument?</b>
              </Typography>
              <Typography variant='body1'>
                If you are looking for a home for an instrument and would like
                to advertise it here, please follow these steps:{" "}
              </Typography>
              <Typography variant='h6' className={classes.step}>
                1
              </Typography>
              <Typography>
                <a href='mailto:rocsatoconservatoryofmusic@gmail.com'>
                  {" "}
                  contact us
                </a>{" "}
                with the following information:
                <ul>
                  <li>Instrument name.</li>
                  <li>
                    Description of instrument, including playing condition.
                  </li>
                  <li>Currency of sale.</li>
                  <li>Amount in your currency.</li>
                  <li>
                    Your name, email address and other contact details to
                    include in the listing.
                  </li>
                  <li>
                    At least one good quality photograph of the instrument.
                  </li>
                </ul>
                <Typography variant='h6' className={classes.step}>
                  2
                </Typography>
                <Typography variant='body1'>
                  Finally, please use the Buy Now button to pay the
                  administration fee of $25. Once we recieve the payment and the
                  required information, we will add your listing to the
                  Marketplace.
                </Typography>
              </Typography>
              <form
                style={{ textAlign: "center", margin: 16 }}
                action='https://www.paypal.com/cgi-bin/webscr'
                method='post'
                target='_blank'
              >
                <input type='hidden' name='cmd' value='_xclick' />
                <input
                  type='hidden'
                  name='business'
                  value='rocsatoconservatoryofmusic@gmail.com'
                />
                <input type='hidden' name='lc' value='US' />
                <input
                  type='hidden'
                  name='item_name'
                  value='Musical Instrument Advert'
                />
                <input type='hidden' name='amount' value='25.00' />
                <input type='hidden' name='currency_code' value='USD' />
                <input type='hidden' name='button_subtype' value='services' />
                <input type='hidden' name='no_note' value='0' />
                <input
                  type='hidden'
                  name='bn'
                  value='PP-BuyNowBF:btn_buynowCC_LG.gif:NonHostedGuest'
                />
                <input
                  type='image'
                  src='https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif'
                  border='0'
                  name='submit'
                  alt='PayPal - The safer, easier way to pay online!'
                />
                <img
                  alt=''
                  border='0'
                  src='https://www.paypalobjects.com/en_US/i/scr/pixel.gif'
                  width='1'
                  height='1'
                />
              </form>

              <Typography className={classes.disclaimer}>
                Rocsato Conservatory of Music is not responsible for the quality
                or frequency of any transactions made, both financially or in
                correspondence as a result of this advert.
              </Typography>
            </Box>
          </Dialog>
          <Dialog open={advertInfo} onClose={() => setAdvertInfo(false)}>
            <Box className={classes.modalContainer}>
              <Typography variant='h6'>
                Retailers: You are welcome to advertise here.
              </Typography>
              <Typography variant='body1'>
                Please contact us for more details.
              </Typography>
            </Box>
          </Dialog>
        </Box>
        <Typography variant='h5'>Used Instruments</Typography>
        {instruments.length === 0 && (
          <Typography>
            There are currently no instruments in the Marketplace. Please check
            back later!
          </Typography>
        )}
        {instruments.length !== 0 && (
          <Box className={classes.inputContainer}>
            <TextField
              value={inputValue}
              onChange={handleInputSearch}
              InputLabelProps={{
                style: {
                  color: "white",
                },
              }}
              InputProps={{
                style: { color: "white" },
              }}
              fullWidth
              label='Type to search used instruments'
            />
          </Box>
        )}

        {uniqueCats.map((cat) => (
          <Button
            className={classes.button}
            onClick={() => filterByCat(cat)}
            variant='contained'
            color='primary'
          >
            {cat}
          </Button>
        ))}

        {!allInsts && (
          <Button
            className={classes.button}
            onClick={showAll}
            variant='contained'
            color='primary'
          >
            Show All{" "}
          </Button>
        )}
        {allInsts &&
          uniqueCats.sort().map((cat) => (
            <>
              <Box>
                <Typography
                  variant='h5'
                  style={{ textTransform: "capitalize" }}
                >
                  {cat}
                </Typography>
              </Box>
              <Box className={classes.cardsContainer}>
                {filteredInstruments
                  .filter((inst) => inst.fields.category === cat)
                  .map((inst) => (
                    <InstrumentCard instrument={inst} />
                  ))}
              </Box>
            </>
          ))}
        {!allInsts && filteredInstruments.length > 0 && (
          <Box className={classes.disclaimerContainer}>
            <Typography className={classes.disclaimer}>
              Descriptions and photographs are provided by the instrument
              vendors. Rocsato Conservatory of Music is not responsible for the
              quality or frequency of any transactions made, both financially or
              in correspondence as a result of this advert.
            </Typography>
          </Box>
        )}
        {!allInsts && filteredInstruments.length > 0 && (
          <Box>
            <Typography variant='h6' style={{ textTransform: "capitalize" }}>
              {filteredInstruments[0].fields.category}
            </Typography>
            <Box className={classes.cardsContainer}>
              {filteredInstruments.map((inst) => (
                <InstrumentCard instrument={inst} />
              ))}
            </Box>
          </Box>
        )}
        {!allInsts && filteredInstruments.length === 0 && (
          <Typography>
            Sorry, there are no results matching your search.
          </Typography>
        )}
      </Box>
      {adverts.length > 0 && (
        <Box className={classes.rightPanel}>
          <Box
            className={classes.advertsTitle}
            style={{ textAlign: "center" }}
            mt={2}
          >
            <Typography variant='h6'>Associated Retailers & Dealers</Typography>
          </Box>
          <Box className={classes.advertsContainer}>
            {adverts.map((ad) => (
              <a href={ad.fields.website} target='_blank'>
                <AdvertCard image={ad.fields.image.fields.file.url} />
              </a>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default withStyles(styles)(InstrumentMarket);

export const ROUTES = [
  { label: "Enroll", path: "/enroll" },
  { label: "Camps & Workshops", path: "/campsworkshops" },
  { label: "Donate", path: "/donate" },
  { label: "Book our Events Venues", path: "/events" },
  { label: "Instrument Marketplace", path: "/instruments" },
  { label: "News", path: "/news" },
  { label: "Gallery", path: "/gallery" },
  // { label: "Policies & Procedures", path: "/policies" },
];

export default (theme) => ({
  bookingCard: {
    position: "relative",
    backgroundColor: "#eee",
    margin: 10,
    borderRadius: 12,
    width: "100%",
  },
  bookingDetailsBox: {
    padding: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    flex: 1,
  },
  deleteIcon: {
    position: "absolute",
    bottom: 8,
    right: 8,
    color: "red",
  },
  text: {
    marginLeft: 16,
  },
  header: {
    fontWeight: "bold",
  },
});

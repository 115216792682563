export default (theme) => ({
  container: {
    color: theme.palette.primary.main,
    padding: theme.spacing(5),
    maxWidth: 1400,
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
    "& a": {
      color: "white",
    },
  },
  headerSection: {
    display: "flex",
    borderRadius: 12,
    boxShadow: "2px 2px 8px black",
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  headerImage: {
    width: 600,
    maxHeight: 400,
    overflow: "hidden",
    "& img": {
      width: "100%",
      padding: "-20%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  panel: {
    borderRadius: 12,
    boxShadow: "2px 2px 8px black",
    padding: theme.spacing(3),
  },
  headerText: {
    width: 600,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  teacherCardContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  teacherCard: {
    minWidth: 300,
    flex: 1,
    padding: theme.spacing(3),

    backgroundColor: theme.palette.primary.main,
    color: "black",
    cursor: "pointer",
    borderRadius: 12,
    margin: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  teacherCardContent: {
    maxWidth: 400,
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
  },
  teacherCardImage: {
    height: 100,
    width: 100,
    overflow: "hidden",
    borderRadius: 100,
    "& img": {
      width: "100%",
      height: 100,
      objectFit: "cover",
    },
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.secondary.main,
    marginBottom: 16,
  },
  galleryImage: {
    width: 200,
    height: 200,
    overflow: "hidden",
    cursor: "pointer",
    margin: theme.spacing(1),
    "& img": {
      width: "170%",
    },
  },
  gallery: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
});

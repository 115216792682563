const client = require("contentful").createClient({
  space: "v077b434hgm4",
  accessToken: "tze3Yg1e6PI_mLvVrjGs1BXQpsrrse9Dm1pnHB0c-50",
});

const getTeachers = () =>
  client
    .getEntries({ content_type: "teacher" })
    .then((response) => response.items);

const getDonatePage = () =>
  client
    .getEntries({ content_type: "donatePage" })
    .then((response) => response.items);

const getEnrollmentPage = () =>
  client
    .getEntries({ content_type: "enrollmentPage" })
    .then((response) => response.items);

const getNews = () =>
  client
    .getEntries({ content_type: "news" })
    .then((response) => response.items);

const getDepartments = () =>
  client
    .getEntries({ content_type: "faculty" })
    .then((response) => response.items);

const getEventsSpaces = () =>
  client
    .getEntries({ content_type: "eventSpace" })
    .then((response) => response.items);

const getPartners = () =>
  client
    .getEntries({ content_type: "partner" })
    .then((response) => response.items);

const getActivities = () =>
  client
    .getEntries({ content_type: "activity" })
    .then((response) => response.items);

const getAboutPage = () =>
  client
    .getEntries({ content_type: "aboutPage" })
    .then((response) => response.items);

const getDepartment = (slug) =>
  client
    .getEntries({ content_type: "faculty", "fields.title": slug })
    .then((response) => response.items);

const getInstrumentsForSale = () =>
  client
    .getEntries({ content_type: "instrumentForSale" })
    .then((response) => response.items);

const getPoliciesProcedure = () =>
  client
    .getEntries({ content_type: "policiesProcedures" })
    .then((response) => response.items);

const getRetailerAdverts = () =>
  client
    .getEntries({ content_type: "retailerAdvert" })
    .then((response) => response.items);

const getGalleryImages = () =>
  client
    .getEntries({ content_type: "galleryImage" })
    .then((response) => response.items);

const getStudentSpotlight = () =>
  client
    .getEntries({ content_type: "studentSpotlight" })
    .then((response) => response.items);

const getCamps = () =>
  client
    .getEntries({ content_type: "camp", order: "fields.order" })
    .then((response) => response.items);

export {
  getCamps,
  getAboutPage,
  getDonatePage,
  getActivities,
  getTeachers,
  getNews,
  getPartners,
  getDepartments,
  getDepartment,
  getEventsSpaces,
  getEnrollmentPage,
  getInstrumentsForSale,
  getPoliciesProcedure,
  getRetailerAdverts,
  getGalleryImages,
  getStudentSpotlight,
};

export default (theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
  },
  enrollModal: {
    padding: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: 500,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  input: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
});

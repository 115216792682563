import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { Box, Typography, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import styles from "./BookingsCardStyles";

const BookingsCard = ({ classes, booking, deleteBooking }) => {
  return (
    <Box className={classes.bookingCard}>
      <Box>
        <Box className={classes.bookingDetailsBox}>
          <Typography className={classes.header}>Contact Name</Typography>
          <Typography className={classes.text}>{booking.contact}</Typography>
        </Box>
        <Box className={classes.bookingDetailsBox}>
          <Typography className={classes.header}>Email</Typography>
          <a href={`mailto:${booking.email}`}>
            <Typography className={classes.text}>{booking.email}</Typography>
          </a>
        </Box>
        {booking.space && (
          <Box className={classes.bookingDetailsBox}>
            <Typography className={classes.header}>Space</Typography>

            <Typography className={classes.text}>{booking.space}</Typography>
          </Box>
        )}
        <Box className={classes.bookingDetailsBox}>
          <Typography className={classes.header}>Time Slot(s)</Typography>

          {booking.time &&
            booking.time.map((time) => (
              <span className={classes.text}>{time},</span>
            ))}
        </Box>
      </Box>
      <Box style={{ display: "flex" }}>
        <Box className={classes.bookingDetailsBox}>
          <Typography className={classes.header}>Booking Details</Typography>
          <Typography className={classes.text}>
            {booking.bookingDetails}
          </Typography>
        </Box>
        <IconButton
          className={classes.deleteIcon}
          style={{ width: 50, height: 50 }}
          onClick={() => deleteBooking(booking)}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(BookingsCard);

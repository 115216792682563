import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { getPoliciesProcedure } from "../../contentful";
import marked from "marked";

import { Box, Typography } from "@material-ui/core";

import styles from "./PoliciesStyles";

const Policies = ({ classes }) => {
  const [policy, setPolicy] = useState({});
  useEffect(() => {
    getPoliciesProcedure().then((data) => setPolicy(data[0].fields));
  }, []);
  return (
    <Box className={classes.container}>
      {policy.text && (
        <Typography dangerouslySetInnerHTML={{ __html: marked(policy.text) }} />
      )}
    </Box>
  );
};

export default withStyles(styles)(Policies);

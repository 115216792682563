export default (theme) => ({
  outerContainer: {
    display: "flex",
    position: "relative",
  },
  container: {
    position: "relative",
    padding: theme.spacing(5),
    color: theme.palette.primary.main,
    minHeight: "100vh",
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      minHeight: 0,
      marginBottom: 200,
    },
  },
  inputContainer: {
    marginBottom: theme.spacing(2),
    width: "60%",
    color: theme.palette.primary.main,
  },
  button: {
    margin: theme.spacing(1),
  },
  cardsContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  rightPanel: {
    minHeight: "100vh",
    overflow: "auto",
    backgroundColor: theme.palette.primary.main,
    width: "25%",
    padding: theme.spacing(2),
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      minHeight: 0,
      overflow: "hidden",
    },
  },
  advertsContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      overflowX: "auto",
    },
  },

  introText: {
    marginBottom: theme.spacing(4),
  },

  modalButtonsContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    margin: theme.spacing(6, 0),
  },
  modalContainer: {
    padding: theme.spacing(5),
    "& li": {
      marginBottom: 8,
    },
  },
  disclaimer: {
    fontSize: 10,
    textAlign: "center",
  },
  disclaimerContainer: {
    margin: theme.spacing(3, 0),
  },

  step: {
    border: "1px solid black",
    width: 15,
    height: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 8,
    borderRadius: 50,
    backgroundColor: "black",
    color: "white",
    margin: theme.spacing(1, 0),
  },
});

import React, { useState, useEffect } from "react";
import { getCamps, getGalleryImages } from "../../contentful";
import CampCard from "../../components/CampCard/CampCard";
import "./camps-styles.scss";
import { SRLWrapper } from "simple-react-lightbox";
import GalleryDisplay from "../../components/GalleryDisplay/GalleryDisplay";
import { Typography } from "@material-ui/core";
const Camps = () => {
  const [camps, setCamps] = useState([]);
  const [images, setImages] = useState([]);
  useEffect(() => {
    getCamps().then((data) => {
      console.log(data);
      setCamps(data);
    });
    getGalleryImages().then((data) => {
      setImages(data.filter((data) => data.fields.camps));
    });
  }, []);
  return (
    <div className='content'>
      {camps
        .filter((c) => !c.fields.archive)
        .map((camp) => (
          <CampCard camp={camp} />
        ))}
      <Typography className='past-camps-divider' variant='h4'>
        Past Camps/Workshops
      </Typography>

      {camps
        .filter((c) => c.fields.archive)
        .map((camp) => (
          <CampCard archive camp={camp} />
        ))}
      {/* <GalleryDisplay images={images} /> */}
      <SRLWrapper>
        <div className='straight-gallery'>
          {images.map((image) => (
            <div className='straight-gallery__image'>
              <img src={image.fields.image.fields.file.url} />
            </div>
          ))}
        </div>
      </SRLWrapper>
    </div>
  );
};

export default Camps;

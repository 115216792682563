export default (theme) => ({
  container: {
    padding: theme.spacing(5),
    minHeight: 400,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyItems: "center",
    color: theme.palette.primary.main,
  },
});

export default (theme) => ({
  container: {
    backgroundColor: "#eee",
    padding: theme.spacing(5),
    // height: "100vh",
  },
  loginPanel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    width: 500,
    height: 300,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(3, 0),
  },
  bookingCard: {
    display: "flex",
    justifyContent: "space-between",
    width: 900,
    marginTop: theme.spacing(2),
    margin: "auto",
    border: "1px solid #bbb",
    borderRadius: 12,
    padding: 10,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      flexDirection: "column",
    },
  },
  datePanel: {
    backgroundColor: "#fff",
    padding: 8,
    marginBottom: theme.spacing(2),
    borderRadius: 12,
  },
  input: {
    margin: theme.spacing(1),
    maxWidth: 300,
  },
  inputRow: {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
  checkIcon: {
    color: "green",
  },
  cancelIcon: {
    color: "darkred",
    cursor: "pointer",
  },
  panel: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(3),
  },
});

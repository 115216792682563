import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { useMainContext } from "../../context";
import map from "../../assets/map.png";

import styles from "./LocationHeroStyles";

const LocationHero = ({ classes }) => {
  const { contactModal, setContactModal } = useMainContext();
  return (
    <Box className={classes.container}>
      <Box className={classes.textContainer}>
        <Box mb={4}>
          <Typography variant='h5'>Come and visit us</Typography>
          <Typography variant='body1'>
            Why not make an apppointment to come and visit, see the facilities
            and book some lessons...
          </Typography>
        </Box>
        <Button
          color='secondary'
          variant='outlined'
          onClick={() => setContactModal(true)}
        >
          Contact Us
        </Button>
      </Box>
      <img src={map} className={classes.map} />
      {/* <iframe
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2981.942902978036!2d-79.68015088456652!3d41.63536557924212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8832c315d6d0c89b%3A0x870b60b9a19ae46d!2sHillhurst%20Mansion!5e0!3m2!1sen!2ses!4v1624866851005!5m2!1sen!2ses'
        width='500'
        height='350'
        allowfullscreen=''
        loading='lazy'
      ></iframe> */}
    </Box>
  );
};

export default withStyles(styles)(LocationHero);

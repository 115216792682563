export default (theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    padding: theme.spacing(5),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
});

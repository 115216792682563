import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Textfield from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import styles from "./BookingsDialogStyles";
import BookingsCard from "../BookingsCard/BookingsCard";
import useSendMail from "../../../hooks/useSendEMail";
import Loading from "../../Loading/Loading";

const BookingsDialog = ({
  classes,
  open,
  onClose,
  selectedDate,
  timeSlots,
  selectedTimes,
  handleTimeSlotClick,
  setBookingName,
  bookingName,
  setBookingEmail,
  bookingEmail,
  setBookingDetails,
  bookingDetails,
  date,
  updateBookings,
  // blockedTimes,
  deleteBooking,
  admin,
  bookings,
  venue,
  spaces,
  setSpace,
  space,
  viewerOnly,
  showAll,
  setSent,
}) => {
  const [blockedTimes, setBlockedTimes] = useState([]);
  const { sendMail, sent, loading } = useSendMail();
  useEffect(() => {
    const times = [];
    selectedDate &&
      selectedDate.forEach((date) => {
        console.log(venue);
        if (date.space === venue) date.time.map((time) => times.push(time));
      });
    console.log(times);
    setBlockedTimes(times);
  }, [selectedDate]);

  const body = `<div><h3>Booking request for ${venue}</h3><p>Date: ${date.format(
    "MM-DD-YYYY"
  )}</p><p>Times: ${JSON.stringify(
    selectedTimes
  )}</p><p>Name: ${bookingName}</p><p>Email: ${bookingEmail}</p><p>Details: ${bookingDetails}</p></div>`;
  const subject = `New booking request for ${venue} from ${bookingName}`;

  const emailBookingRequest = () => {
    sendMail(body, subject);
    if (sent) {
      onClose();
      setSent();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box className={classes.container}>
        <Box mb={2}>
          <Typography variant='h4'>{date.format("MMMM DD, YYYY")}</Typography>
        </Box>
        {!viewerOnly && (
          <Box className={classes.bookingContainer}>
            {blockedTimes.length === 7 ? (
              <Box>
                <Typography>
                  Sorry, this space is no longer available on this date.
                </Typography>
              </Box>
            ) : (
              <>
                <Box className={classes.timesContainer}>
                  <Box mb={2}>
                    <Typography>Please select your time(s)</Typography>
                    <Typography style={{ fontSize: 11 }}>
                      Please include set-up and break-down in your reservation
                      request.
                    </Typography>
                  </Box>
                  {timeSlots.map((slot) => (
                    <Button
                      disabled={blockedTimes.includes(slot)}
                      onClick={() => handleTimeSlotClick(slot)}
                      variant='contained'
                      color={
                        selectedTimes.includes(slot) ? "secondary" : "primary"
                      }
                    >
                      {slot}
                    </Button>
                  ))}
                </Box>
                <Box className={classes.bookingForm}>
                  {admin && (
                    <>
                      <InputLabel id='demo-simple-select-label'>
                        {" "}
                        <Typography>Please select a venue</Typography>
                      </InputLabel>

                      <Select className={classes.input} onChange={setSpace}>
                        {spaces &&
                          spaces.map((space) => (
                            <MenuItem value={space.fields.name}>
                              {space.fields.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </>
                  )}
                  <Textfield
                    className={classes.input}
                    value={bookingName}
                    onChange={(e) => setBookingName(e.target.value)}
                    variant='outlined'
                    label='Name'
                  />
                  <Textfield
                    className={classes.input}
                    value={bookingEmail}
                    type='email'
                    onChange={(e) => setBookingEmail(e.target.value)}
                    variant='outlined'
                    label='Email'
                  />
                  <Textfield
                    className={classes.input}
                    value={bookingDetails}
                    onChange={(e) => setBookingDetails(e.target.value)}
                    variant='outlined'
                    label='Details'
                    multiline
                    rows={6}
                  />
                  {!admin && (
                    <form
                      action='https://formspree.io/f/xoqylnnj'
                      method='POST'
                    >
                      <input hidden name='Name' value={bookingName} />
                      <input hidden name='Email' value={bookingEmail} />
                      <input hidden name='Venue' value={venue} />
                      <input
                        hidden
                        name='Date'
                        value={date.format("MM-DD-YYYY")}
                      />
                      {selectedTimes &&
                        selectedTimes.map((time) => (
                          <input hidden name='Time' value={time} />
                        ))}
                      <input hidden name='Details' value={bookingDetails} />
                      {!loading && (
                        <Button
                          fullWidth
                          type='button'
                          variant='contained'
                          color='secondary'
                          onClick={emailBookingRequest}
                          disabled={
                            date === "" ||
                            bookingDetails === "" ||
                            bookingEmail === "" ||
                            bookingName === "" ||
                            selectedTimes.length === 0
                          }
                        >
                          Submit Booking
                        </Button>
                      )}
                      {loading && <Loading />}
                    </form>
                  )}
                  {admin && (
                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={updateBookings}
                      disabled={
                        date === "" ||
                        bookingDetails === "" ||
                        bookingEmail === "" ||
                        bookingName === "" ||
                        space === "" ||
                        selectedTimes === []
                      }
                    >
                      Add
                    </Button>
                  )}
                  <Button onClick={onClose}>Close</Button>
                </Box>
              </>
            )}
          </Box>
        )}
        {admin && (
          <Box mt={5} mb={3}>
            {" "}
            <Typography variant='h6'>Bookings {venue} </Typography>{" "}
          </Box>
        )}
        {admin &&
          bookings &&
          bookings
            .filter((booking) => booking.date === date.format("MM-DD-YYYY"))
            .map((booking) => {
              if (venue && booking.space === venue) {
                return (
                  <BookingsCard
                    booking={booking}
                    deleteBooking={deleteBooking}
                  />
                );
              } else if (showAll) {
                return (
                  <BookingsCard
                    booking={booking}
                    deleteBooking={deleteBooking}
                  />
                );
              }
            })}
      </Box>
    </Dialog>
  );
};

export default withStyles(styles)(BookingsDialog);

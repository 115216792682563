import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { getDepartments } from "../../contentful";
import { Link } from "react-router-dom";
import "./DepartmentsHeroStyles.scss";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const DepartmentsHero = ({ classes }) => {
  const [departments, setDepartments] = useState([]);
  useEffect(() => {
    getDepartments().then((data) => setDepartments(data));
  }, []);
  return (
    <>
      <Box style={{ textAlign: "center" }}>
        <Typography color='primary' variant='h4'>
          Study at RCM
        </Typography>
      </Box>
      <Box className='department-card__container'>
        {departments.map((dep) => (
          <Link
            style={{ textDecoration: "none" }}
            to={`/department/${dep.fields.title}`}
          >
            <Box className='department-card'>
              {dep.fields.mainImage && (
                <div className='department-card__image'>
                  <img src={dep.fields.mainImage.fields.file.url} />
                </div>
              )}
              <Typography className="department-card__title" variant='h5'>{dep.fields.title}</Typography>
            </Box>
          </Link>
        ))}
      </Box>
    </>
  );
};

export default DepartmentsHero;

export default (theme) => ({
  container: {
    padding: theme.spacing(5),
    color: theme.palette.primary.main,
    boxShadow: "2px 2px 8px black",
    borderRadius: 12,
    marginTop: theme.spacing(3),
  },
  teacherCard: {
    margin: theme.spacing(7, 4),
    borderRadius: 12,
    display: "flex",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  departmentListItem: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  departmentTitle: {
    fontWeight: "bold",
  },
  avatar: {
    height: 100,
    width: 100,
    overflow: "hidden",
    borderRadius: 100,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    objectPosition: "center",
    "& img": {
      width: "100%",
      height: 100,
      objectFit: "cover",
    },
  },
});

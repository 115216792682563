import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Textfield from "@material-ui/core/TextField";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Logo from "../../assets/LOGO.png";
import useSendEmail from "../../hooks/useSendEMail";
import Loading from "../Loading/Loading";

import styles from "./EnrollmentHeroStyles";

const AGES = ["8-18", "19-35", "36-50", "51-75"];

const EnrollmentHero = ({ classes }) => {
  const { sendMail, sent, loading } = useSendEmail();
  const [enrollOpen, setEnrollOpen] = useState(false);

  const [name, setName] = useState("");
  const [instrument, setInstrument] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [experience, setExperience] = useState("");
  const [aspirations, setAspirations] = useState("");
  const [age, setAge] = useState("");

  const body = `<div><h3>New Enrollment Application</h3><p>Name: ${name}</p><p>Instrument/Voice: ${instrument}</p><p>Address: ${address}</p><p>Phone: ${phone}</p><p>Email: ${email}</p><p>Experience: ${experience}</p><p>Aspirations: ${aspirations}</p><p>Age: ${age}</p></div>`;
  const subject = `New enrollment application from ${name}`;

  const sendEMail = (e) => {
    sendMail(body, subject);
    setEnrollOpen(false);
  };

  const formValid = () => {
    if (
      name &&
      instrument &&
      address &&
      phone &&
      email &&
      experience &&
      aspirations &&
      age
    )
      return true;
  };
  return (
    <div>
      <Box className={classes.container} mt={4}>
        {!sent && (
          <Button
            onClick={() => setEnrollOpen(true)}
            color='secondary'
            variant='outlined'
          >
            Click here to start your enrollment
          </Button>
        )}
        {sent && (
          <Typography>
            Thankyou for your application. We will be in touch as soon as
            possible.
          </Typography>
        )}
        {loading && <Loading />}
        <Box mt={4} style={{ textAlign: "center" }}>
          <Typography variant='body1'>
            Can you study online? Absolutely!
          </Typography>
          <Box mt={2}>
            <Typography variant='body2'>
              <em>
                Develop your voice or instrument from the comfort and privacy of
                your own home....... All you need is a computer, laptop, or
                tablet and a great internet connection!
              </em>
            </Typography>
            <Box mt={2}>
              <Typography variant='body2'>
                More information is available on our{" "}
                <a href='/policies' target='_blank'>
                  {" "}
                  Policies & Procedures
                </a>{" "}
                page.{" "}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog open={enrollOpen} onClose={() => setEnrollOpen(false)}>
        <Box className={classes.enrollModal}>
          <Box mb={4}>
            <img src={Logo} style={{ height: 100 }} />
            <Typography variant='h5'>Enrollment Form</Typography>
            <Box mt={1}>
              <Typography>
                Please use this form to tell us about yourself, and we will
                contact you to arrange your lessons.
              </Typography>
            </Box>
          </Box>
          <Box>
            <form
              // action='https://formspree.io/f/xoqylnnj'
              // method='POST'
              className={classes.form}
              // onSubmit={sendMail}
              // ref={form}
            >
              <Textfield
                className={classes.input}
                label='Name'
                variant='outlined'
                name='name'
                onChange={(e) => setName(e.target.value)}
              />

              <Textfield
                className={classes.input}
                label='Instrument or Voice'
                variant='outlined'
                name='Instrument'
                onChange={(e) => setInstrument(e.target.value)}
              />
              <Textfield
                className={classes.input}
                label='Address'
                multiline
                variant='outlined'
                name='Address'
                rows={4}
                onChange={(e) => setAddress(e.target.value)}
              />
              <Textfield
                className={classes.input}
                label='Phone'
                type='number'
                variant='outlined'
                name='Tel'
                onChange={(e) => setPhone(e.target.value)}
              />
              <Textfield
                className={classes.input}
                label='Email'
                type='email'
                variant='outlined'
                name='reply_to'
                onChange={(e) => setEmail(e.target.value)}
              />
              <Textfield
                className={classes.input}
                name='Experience'
                variant='outlined'
                label='Any previous experience:'
                placeholder='You can include any lessons or, groups you have played with, or just reasons for motivation to apply'
                multiline
                rows={6}
                onChange={(e) => setExperience(e.target.value)}
              />
              <Textfield
                className={classes.input}
                label='Aspirations'
                variant='outlined'
                name='Aspirations'
                multiline
                rows={6}
                onChange={(e) => setAspirations(e.target.value)}
              />
              <Box mb={1} style={{ textAlign: "left" }}>
                <Typography>Age Group</Typography>
              </Box>
              {AGES.map((age) => (
                <Box mb={1} style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type='radio'
                    name='Age'
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                  />
                  <small>{age}</small>
                </Box>
              ))}
              <Box>
                <ButtonGroup variant='outlined'>
                  <Button onClick={() => setEnrollOpen(false)}>Cancel</Button>
                  <Button
                    disabled={!formValid()}
                    type='button'
                    onClick={sendEMail}
                  >
                    Submit
                  </Button>
                </ButtonGroup>
              </Box>
            </form>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(EnrollmentHero);

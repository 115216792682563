export default (theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexWrap: "wrap",
    minHeight: 500,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    padding: theme.spacing(5),
  },
  textContainer: {
    width: 500,
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(5),
    },
  },
  map: {
    width: 450,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
});

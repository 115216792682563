export default (theme) => ({
  container: {
    margin: 8,
    [theme.breakpoints.down("xs")]: {
      height: 75,
      width: 100,
    },

    "& img": {
      maxWidth: "100%",
      [theme.breakpoints.down("xs")]: {
        height: "100%",
      },
    },
  },
});

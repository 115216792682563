import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import EmailIcon from "@material-ui/icons/Email";

import { useMainContext } from "../../context";
import ContactModal from "../ContactModal";
import SideNav from "../SideNav";
import Logo from "../../assets/LOGO.png";

import styles from "./AppBarStyles";

const MyAppBar = ({ classes }) => {
  const [sideNav, setSideNav] = useState(false);
  const { contactModal, setContactModal } = useMainContext();
  return (
    <AppBar>
      <ContactModal
        open={contactModal}
        onClose={() => setContactModal(false)}
      />
      <Box className={classes.container}>
        <Box>
          <Link to='/'>
            <img src={Logo} style={{ height: 80 }} />
          </Link>
        </Box>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Box className={classes.contactButton}>
            <Button
              onClick={() => setContactModal(true)}
              color='secondary'
              variant='outlined'
            >
              Contact Us
            </Button>
          </Box>
          <IconButton
            className={classes.emailIcon}
            onClick={() => setContactModal(true)}
          >
            <EmailIcon />
          </IconButton>
          <IconButton onClick={() => setSideNav(true)}>
            <MenuIcon />
          </IconButton>
        </Box>
      </Box>
      <SideNav open={sideNav} onClose={() => setSideNav(false)} />
    </AppBar>
  );
};

export default withStyles(styles)(MyAppBar);

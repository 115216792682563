import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { getDonatePage } from "../../contentful";
import marked from "marked";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import styles from "./DonatePageStyles";
import { connect } from "react-redux";

const DonatePage = ({ classes }) => {
  const [content, setContent] = useState({});

  useEffect(() => {
    getDonatePage().then((data) => setContent(data[0].fields));
  });
  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Box className={classes.headerText}>
          <Typography variant='h4'>{content.title}</Typography>
          <Typography variant='h6'>{content.subtitle}</Typography>
        </Box>
        {content.image && <img src={content.image.fields.file.url} />}
      </Box>
      {content.text && (
        <Typography
          dangerouslySetInnerHTML={{ __html: marked(content.text) }}
        />
      )}
      <form
        style={{ display: "flex", justifyContent: "center" }}
        action='https://www.paypal.com/donate'
        method='post'
        target='_blank'
      >
        <input type='hidden' name='hosted_button_id' value='4JX876KC2E6VU' />
        <input
          type='image'
          src='https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif'
          border='0'
          name='submit'
          title='PayPal - The safer, easier way to pay online!'
          alt='Donate with PayPal button'
        />
        <img
          alt=''
          border='0'
          src='https://www.paypal.com/en_US/i/scr/pixel.gif'
          width='1'
          height='1'
        />
      </form>
    </Box>
  );
};

export default withStyles(styles)(DonatePage);

export default (theme) => ({
  container: {
    padding: theme.spacing(5),
    color: theme.palette.primary.main,
  },
  avatar: {
    height: 150,
    width: 150,
    overflow: "hidden",
    borderRadius: 100,
    objectPosition: "center",
    "& img": {
      width: "100%",
    },
  },
  cardsContainer: {},
  partnerCard: {
    display: "flex",
    textAlign: "center",
    margin: theme.spacing(2),
    borderRadius: 12,
    boxShadow: "2px 2px 8px black",
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  cardText: {
    textAlign: "left",
    marginLeft: theme.spacing(4),
  },
});

import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { Box, Typography } from "@material-ui/core";

import Logo from "../../assets/LOGO.png";

import styles from "./ThankyouPageStyles";

const ThankyouPage = ({ classes }) => (
  <div>
    <Box className={classes.container}>
      <img src={Logo} />
      <Typography className={classes.text}>
        Thank you for contacting us. We will respond as soon as possible.
      </Typography>
    </Box>
  </div>
);

export default withStyles(styles)(ThankyouPage);

import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { getDepartments } from "../../contentful";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Facebook from "@material-ui/icons/Facebook";
import Instagram from "@material-ui/icons/Instagram";
import Mail from "@material-ui/icons/Mail";
import { useMainContext } from "../../context";

import { ROUTES } from "../../consts/navigation";
import Logo from "../../assets/LOGO.png";

import styles from "./SideNavStyles";

const SideNav = ({ classes, open, onClose }) => {
  const [depts, setDepts] = useState([]);
  const { setContactModal } = useMainContext();

  const handleMailClick = () => {
    setContactModal(true);
    onClose();
  };

  useEffect(() => {
    getDepartments().then((data) => setDepts(data));
  }, []);
  return (
    <Drawer open={open} onClose={onClose} anchor='right'>
      <Box className={classes.container}>
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {/* <Box mb={2}>
          <Link className={classes.logo} onClick={onClose} to='/'>
            <img src={Logo} style={{ width: 120 }} />
          </Link>
        </Box> */}
        <Typography variant='h6' color='secondary' className={classes.linkText}>
          About RCM:
        </Typography>
        <Box className={classes.linksContainer}>
          <Link onClick={onClose} className={classes.link} to='/about'>
            <Typography color='secondary' className={classes.linkText}>
              Founders & Instructors
            </Typography>
          </Link>
          <Link onClick={onClose} className={classes.link} to='/partners'>
            <Typography color='secondary' className={classes.linkText}>
              Partners
            </Typography>
          </Link>
          <Box className={classes.divider} />
          <Typography
            variant='h6'
            color='secondary'
            className={classes.linkText}
          >
            Departments:
          </Typography>

          {depts.map((link) => (
            <Link
              onClick={onClose}
              className={classes.link}
              to={`/department/${link.fields.title}`}
            >
              <Typography color='secondary' className={classes.linkText}>
                {link.fields.title}
              </Typography>
            </Link>
          ))}
          <Box className={classes.divider} />

          <Link
            onClick={onClose}
            className={classes.link}
            to={`/student-spotlight`}
          >
            <Typography color='secondary' className={classes.linkText}>
              Student Spotlight{" "}
            </Typography>
          </Link>
          <Box className={classes.divider} />
          <Typography
            variant='h6'
            color='secondary'
            className={classes.linkText}
          >
            Participate:
          </Typography>
          {ROUTES.map((link) => (
            <Link onClick={onClose} className={classes.link} to={link.path}>
              <Typography color='secondary' className={classes.linkText}>
                {link.label}
              </Typography>
            </Link>
          ))}
        </Box>
        <Box>
          <a
            href='https://www.facebook.com/rocsatoconservatoryofmusic'
            target='_blank'
          >
            <IconButton>
              <Facebook style={{ color: "rgb(90, 0, 0)" }} />
            </IconButton>
          </a>
          <a
            href='https://www.instagram.com/rocsatoconservatory/'
            target='_blank'
          >
            <IconButton>
              <Instagram style={{ color: "rgb(90, 0, 0)" }} />
            </IconButton>
          </a>
          <IconButton onClick={handleMailClick}>
            <Mail style={{ color: "rgb(90, 0, 0)" }} />
          </IconButton>
        </Box>
      </Box>
    </Drawer>
  );
};

export default withStyles(styles)(SideNav);

export default (theme) => ({
  container: {
    padding: theme.spacing(5),
  },
  headerContainer: {
    display: "flex",
    justifyContent: "center",
  },
  headerText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 300,
  },
  imageContainer: {
    width: 300,
    height: 300,
    borderRadius: 12,
    overflow: "hidden",
    "& img": {
      width: "100%",
      height: 300,
      objectFit: "cover",
    },
  },
  departmentChip: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: 12,
  },
  departmentTitle: {
    fontSize: 10,
  },
  link: {
    textDecoration: "none",
  },
});

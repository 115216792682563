export default (theme) => ({
  container: {
    width: 300,
    padding: theme.spacing(3, 5),
    display: "flex",
    flexDirection: "column",
    height: "100%",
    position: "relative",
  },
  linksContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: "90%",
  },
  link: {
    textDecoration: "none",
  },
  linkText: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1),
    },
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.primary.main,
  },

  logo: {
    position: "absolute",
    top: 16,
  },
});

import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { useMainContext } from "../../context";

import { Box, Typography, Dialog } from "@material-ui/core";

import SmartCalendar from "../SmartCalendar";

import styles from "./EventsBookingDialogStyles";

const EventsBookingDialog = ({ onClose, open, space, classes }) => {
  const { venueBookings, fetchBookings } = useMainContext();
  const [dates, setDates] = useState([]);
  const [venueName, setVenueName] = useState("");
  const [sent, setSent] = useState(false);

  useEffect(() => {
    space && setVenueName(space.name);
  }, [space]);

  useEffect(() => {
    fetchBookings();
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <Box className={classes.formContainer}>
        {space && (
          <Box mb={3}>
            <Typography variant='h5'>Book the {space.name} now</Typography>
          </Box>
        )}

        {space && (
          <SmartCalendar setSent={() => setSent(true)} venue={space.name} />
        )}
        {sent && (
          <Box mt={3}>
            <Typography color='secondary'>
              Thanks for your booking. We will be in touch soon to confirm.
            </Typography>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default withStyles(styles)(EventsBookingDialog);

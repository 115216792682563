import React from "react";
import MainHero from "../../components/MainHero/MainHero";
import WelcomeHero from "../../components/WelcomeHero/WelcomeHero";
import DepartmentsHero from "../../components/DepartmentsHero/DepartmentsHero";
import LocationHero from "../../components/LocationHero";
import ActivitiesHero from "../../components/ActivitiesHero";
import CoffeeHero from "../../components/CoffeeHero";

const Home = () => (
  <div>
    <MainHero />
    <WelcomeHero />
    <DepartmentsHero />
    <ActivitiesHero />
    <LocationHero />
    <CoffeeHero />
  </div>
);

export default Home;

export default (theme) => ({
  container: {
    padding: theme.spacing(5),
    color: theme.palette.primary.main,
  },
  portraits: {
    width: 400,
    display: "flex",
  },
  portraitWrapper: {
    width: 200,
    height: 180,
    objectFit: "cover",
    overflow: "hidden",
    margin: theme.spacing(1),

    "& img": { width: "100%" },
  },
  foundersContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
});

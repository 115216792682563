export default (theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 12,
    padding: theme.spacing(3),
    margin: theme.spacing(3),
    width: "fit-content",
    maxWidth: 500,
    "& img": {
      maxWidth: 250,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      flexDirection: "column",
      margin: theme.spacing(2, 0),
      
    },
  },
  galleryContainer: {
    width: "30%",
    marginLeft: theme.spacing(1),
    "& img": {
      width: "50%",
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  divider: {
    height: 0.5,
    width: "75%",
    margin: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
  },
  title: {
    fontWeight: "bold",
  },
});
